var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed z-10 inset-0 overflow-y-auto",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('FormulateForm',{on:{"submit":_vm.procesar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"},[_c('div',{staticClass:"bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"},[_c('div',{staticClass:"sm:flex sm:items-start"},[_c('div',{staticClass:"mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900",attrs:{"id":"modal-title"}},[_vm._v(" Cambiar entradas a otro evento ")]),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-sm text-gray-500"},[_vm._v(" En total son "+_vm._s(_vm.compra.entradas.length)+" entrada"+_vm._s(_vm.compra.entradas.length > 1 ? "s" : "")+" a nombre de "),_c('strong',[_vm._v(_vm._s(_vm.primerEntrada.nombre)+" "+_vm._s(_vm.primerEntrada.apellido))])])]),_c('div',{staticClass:"mt-2 w-full"},[(_vm.loader)?_c('w-loading',{staticClass:"w-full",attrs:{"label":_vm.loader === 'processing' ? 'Generando Entradas' : null}}):_c('div',[_c('FormulateInput',{attrs:{"type":"select","name":"evento","label":"Evento","placeholder":"Selecciona un evento","validation":"required","help":"Selecciona el evento al que se cambiarán las entradas","options":_vm.eventos.map(function (entrada) {
                        return {
                          value: entrada._id,
                          label: [
                            entrada.titulo,
                            entrada.variables.fecha ].join(' - '),
                        };
                      })}})],1)],1)])])]),_c('div',{staticClass:"bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"},[_c('button',{staticClass:"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm",class:isLoading ? 'opacity-50 cursor-not-allowed' : '',attrs:{"disabled":isLoading,"type":"submit"}},[_vm._v(" Cambiar entradas ")]),_c('button',{staticClass:"mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" Cancelar ")])])])])]}}]),model:{value:(_vm.participante),callback:function ($$v) {_vm.participante=$$v},expression:"participante"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }