<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <FormulateForm
      v-model="participante"
      @submit="procesar"
      #default="{ isLoading }"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <!-- <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div> -->
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow"
              >
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Agregar Entradas
                </h3>
                <!-- Div descriptivo del uso -->
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Agregá una persona, la cantidad de entradas que se le van a
                    asignar.
                  </p>
                </div>
                <div class="mt-2 w-full">
                  <w-loading
                    v-if="loader"
                    :label="
                      loader === 'processing' ? 'Generando Entradas' : null
                    "
                    class="w-full"
                  />
                  <div v-else>
                    <FormulateInput
                      type="select"
                      class=""
                      name="nombreEntrada"
                      label="Tipo de entrada"
                      placeholder="Entrada"
                      validation="required"
                      validation-name="entrada"
                      :options="
                        evento.entradas.map((entrada) => {
                          return {
                            value: entrada.nombre,
                            label: entrada.nombre,
                          };
                        })
                      "
                    />

                    <div class="flex mb-0 p-0">
                      <FormulateInput
                        type="text"
                        class="w-1/2 mr-1 mb-0"
                        name="nombre"
                        label="Nombre"
                        placeholder="Nombre"
                        validation="required"
                        validation-name="nombre"
                      />
                      <FormulateInput
                        class="w-1/2 ml-1 mb-0"
                        type="text"
                        name="apellido"
                        label="Apellido"
                        placeholder="Apellido"
                        validation="required"
                        validation-name="apellido"
                      />
                    </div>

                    <FormulateInput
                      type="email"
                      name="email"
                      label="Correo electrónico"
                      placeholder="Correo electrónico"
                      validation="required|email"
                      validation-name="Correo electrónico"
                    />
                    <!-- Telefono -->
                    <FormulateInput
                      type="text"
                      name="telefono"
                      label="Teléfono"
                      placeholder="Teléfono"
                      validation="required"
                      validation-name="Teléfono"
                    />
                    <!-- Select cantidad de entradas -->
                    <FormulateInput
                      type="select"
                      name="cantidad"
                      label="Cantidad de entradas"
                      placeholder="Cantidad de entradas"
                      validation="required"
                      validation-name="Cantidad de entradas"
                      :options="[
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9' },
                        { value: 10, label: '10' },
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              :disabled="isLoading"
              type="submit"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              :class="isLoading ? 'opacity-50 cursor-not-allowed' : ''"
            >
              Agregar participantes
            </button>
            <button
              @click="hideModal()"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import WLoading from "./WLoading.vue";
export default {
  components: { WLoading },
  name: "WAndreaniConfirm",
  props: {
    compra: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    primerEntrada() {
      if (!this.compra) return null;
      return this.compra.entradas[0];
    },
    total() {
      if (!this.ids) return 0;
      return this.ids.length;
    },
    loader() {
      if (this.busy) return this.busy;
      return this.ids === null;
    },
  },
  data() {
    return {
      busy: true,
      groupName: "",
      participante: {},
      evento: {},
    };
  },
  async created() {
    this.evento = await this.getEvento(this.$route.params.id);
    this.participante.cantidad = 1;
    this.participante.nombreEntrada = this.evento.entradas[0].nombre;
    this.busy = false;
  },
  mounted() {},
  methods: {
    ...mapActions("pago-evento", {
      create: "create",
    }),
    ...mapActions("eventos", {
      getEvento: "get",
    }),
    hideModal() {
      this.$emit("hide");
    },

    crearEntrada({ nombre, apellido, email, telefono }) {
      return {
        entrada: {
          precio: {
            ars: 0,
          },
          nombre: "Entrada general",
        },
        nombreEntrada: "Entrada general",
        precio: {
          ars: 0,
        },
        cantidad: 1,
        nombre,
        apellido,
        email,
        telefono,
        cortesia: true,
      };
    },

    async procesar(participante) {
      console.log("procesar");
      this.busy = "processing";
      // Enviamos la solicitud para generar todas las etiquetas.
      const evento = this.evento;
      console.log("evento", evento);
      console.log("participante", participante);
      //   Creamos el objeto entradas
      const entradas = [];

      //   Crear entradas tantas como participante.cantidad)
      for (let i = 0; i < participante.cantidad * 1; i++) {
        entradas.push(
          this.crearEntrada({
            nombreEntrada: participante.nombreEntrada,
            evento,
            nombre: participante.nombre,
            apellido: participante.apellido,
            email: participante.email,
            telefono: participante.telefono,
            cantidad: 1,
          })
        );
      }

      console.log(entradas);

      const pagoEvento = {
        evento,
        entradas,
        tipo: "cortesia",
        email: participante.email,
        status: "pago",
        paidAmount: 0,
      };

      await this.create(pagoEvento);

      this.busy = false;
      //   REDIRECCIONAR CON EL FILTRO
      this.$emit("filtrar", participante.email);
      this.hideModal();
    },
  },
};
</script>
