<template>
  <div>
    <w-confirmar-devolucion-entrada
      v-if="showReturn"
      @hide="showReturn = false"
      :compra="returnItem"
    />
    <w-agregar-entradas
      @filtrar="filtrar"
      v-if="showAgregarEntradas"
      @hide="showAgregarEntradas = false"
    />
    <w-cambiar-evento
      @filtrar="filtrar"
      :compra="returnItem"
      v-if="showCambiarEvento"
      @hide="showCambiarEvento = false"
    />
    <w-top-bar>Entradas vendidas</w-top-bar>
    <div v-if="event" class="h-full bg-gray-200 relative">
      <div class="flex flex-col md:flex-row md:space-x-4 bg-white rounded p-4">
        <div class="md:w-24 md:h-24 overflow-hidden rounded mb-4 md:mb-0">
          <img
            :src="event.image"
            alt="Imagen en miniatura redonda"
            class="w-full h-full object-cover"
          />
        </div>
        <div>
          <h3 class="text-xl">{{ event.titulo }}</h3>
          <h5 class="text-md">{{ event.date | formatDate }}</h5>
          <h5 class="text-md">{{ event.direccion }}</h5>
          <div class="text-sm mt-1">
            <div class="flex items-center" v-if="event.estado == 'en-venta'">
              <div class="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
              <p>En venta</p>
            </div>
            <div class="flex items-center" v-if="event.estado == 'agotado'">
              <div class="w-4 h-4 rounded-full bg-yellow-500 mr-2"></div>
              <p>Agotado</p>
            </div>
            <div class="flex items-center" v-if="event.estado == 'proximamente'">
              <div class="w-4 h-4 rounded-full bg-gray-500 mr-2"></div>
              <p>Próximamente</p>
            </div>
            <div class="flex items-center" v-if="event.estado == 'cerrado'">
              <div class="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
              <p>Cerrado</p>
            </div>
            <div class="flex items-center" v-if="event.estado == 'cancelado'">
              <div class="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
              <p>Cancelado</p>
            </div>
          </div>
        </div>

        <!-- Boton para agregar entradas manualmente -->
        <div class="md:absolute md:right-4 md:top-4 flex">
          <button
            @click="agregarEntradas"
            class="relative mr-4 md:mt-0 flex justify-right px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md group hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </button>

          <button
            v-if="compras.total > 0"
            @click="descargarParticipantes"
            class="relative mt-4 md:mt-0 flex justify-right px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="absolute inset-y-0 right-0 flex items-right pr-3 mt-2">
              <svg
                v-if="downloading"
                class="w-5 h-5 mr-1 text-indigo-500 animate-spin group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              <svg
                v-else
                class="w-5 h-5 mr-1 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </span>
            <span class="mr-6">Participantes</span>
            <div
              class="absolute top-0 right-0 px-2 py-1 -mt-2 -mr-2 text-xs bg-red-500 rounded-full"
            >
              {{ totalEntradas }}
            </div>
          </button>

          <button
            v-if="compras.total > 0"
            @click="descargarVentas"
            class="ml-2 relative mt-4 md:mt-0 flex justify-right px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="absolute inset-y-0 right-0 flex items-right pr-3 mt-2">
              <svg
                v-if="downloading"
                class="w-5 h-5 mr-1 text-indigo-500 animate-spin group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              <svg
                v-else
                class="w-5 h-5 mr-1 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </span>
            <span class="mr-6">Ventas</span>
            <div
              class="absolute top-0 right-0 px-2 py-1 -mt-2 -mr-2 text-xs bg-red-500 rounded-full"
            >
              {{ totalEntradas }}
            </div>
          </button>
        </div>
      </div>
      <div class="p-4">
        <div class="">
          <div class="flex flex-wrap space-y-3 md:flex-nowrap md:space-y-0 md:space-x-6">
            <div class="relative w-full bg-white rounded-lg shadow md:w-1/2">
              <div class="flex items-center py-4">
                <div>
                  <svg
                    class="w-6 h-6 m-4 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="flex-grow text-xs leading-snug">
                  <div class="font-medium text-gray-600">
                    <span class="mr-1 font-semibold">{{ compras.total }}</span
                    >ventas
                  </div>
                  <div class="text-base font-semibold">
                    <w-price-v-2
                      class="text-2xl"
                      :currency="currency"
                      :value="totalVentas"
                      :decimals="2"
                    />
                  </div>
                  <div
                    class="inline-flex"
                    v-tooltip="`Total neto (con descuentos de procesadores)`"
                  >
                    <span class="text-base font-semibold">
                      <w-price-v-2
                        class="text-gray-500"
                        :currency="currency"
                        :value="totalVentasNeto"
                        :decimals="0"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative w-full overflow-hidden bg-white rounded-lg shadow md:w-1/2">
              <div class="flex items-start">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 m-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                    />
                  </svg>
                </div>
                <div class="flex-grow text-xs leading-snug py-4">
                  <div class="font-medium text-gray-600">Entradas vendidas</div>
                  <div class="text-2xl font-semibold">{{ totalEntradas }} / {{ totalCupos }}</div>

                  <div class="mb-2" v-for="venta in ventas" :key="venta._id">
                    <div class="font-normal leading-5">
                      <span class="font-light">{{ venta.total }}</span
                      >/<span class="font-semibold">{{ venta.cupo }}</span> -
                      <span class="font-semibold">{{ venta._id.entrada || venta._id }}</span>
                    </div>
                    <div class="w-64 md-w-128">
                      <RangeBar
                        :cuposDisponibles="venta.total"
                        :cuposTotales="venta.cupo"
                        class="range-bar mt-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- component -->
      <div class="relative pt-2 mb-2 text-gray-600 mx-4">
        <input
          class="w-full h-10 text-sm bg-white border-2 border-gray-300 rounded-lg focus:outline-none"
          type="search"
          v-model="searchTerm"
          v-debounce:1000ms="doSearch"
          @change="doSearch()"
          name="search"
          placeholder="Buscar por nombre, correo electrónico"
        />
        <button @click="doSearch()" type="submit" class="absolute top-0 right-0 mt-5 mr-4">
          <svg
            class="w-4 h-4 text-gray-600 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966"
            xml:space="preserve"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </div>
      <div
        class="flex flex-col items-center justify-center h-48"
        v-if="compras.length === 0 && !loading"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-12 h-12 text-base-300"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="my-2 font-normal text-gray-700">No hay compras</div>
      </div>
      <div v-else class="overflow-hidden shadow bshipment-b bshipment-gray-200 sm:rounded-lg">
        <div class="w-full">
          <div class="bg-white border border-gray-400 overflow-x-scroll mx-4 rounded my-6">
            <table class="min-w-max w-full table-auto">
              <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th class="py-3 px-6 font-medium text-left">Nombre</th>
                  <th class="py-3 px-6 font-medium text-left">Compra</th>
                  <th class="py-3 px-6 font-medium text-center">Monto Total</th>
                </tr>
              </thead>
              <tbody class="text-sm font-light">
                <tr
                  v-for="item in comprasFiltradas"
                  :key="item._id"
                  class="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td class="py-3 px-6 text-left whitespace-nowrap">
                    <div class="text-xs leading-5 md:text-sm">
                      {{ item.entradas[0].nombre | capitalizar }}
                      {{ item.entradas[0].apellido | capitalizar }}
                    </div>

                    <div class="text-xs leading-5 text-gray-500 md:text-sm">
                      {{ item.email }}
                    </div>
                    <div class="text-xs italic text-gray-500">
                      hace
                      {{ item.createdAt | moment("from", true) }}
                    </div>
                  </td>
                  <td class="py-3 px-6 text-left">
                    <div
                      v-for="(it, itIndex) in item.entradas"
                      :key="itIndex"
                      class="text-xs flex items-start space-x-2 mb-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                        />
                      </svg>

                      <div class="flex flex-col space-y-1 space-x-1">
                        <div class="font-normal">
                          {{ it.nombre | capitalizar }}
                          {{ it.apellido | capitalizar }}
                        </div>
                        <div>{{ 1 }} x {{ it.nombreEntrada }}</div>

                        <div v-if="it.institucion" class="text-xs flex items-start space-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                            />
                          </svg>

                          <div>{{ it.institucion }}</div>
                        </div>
                        <div v-if="it.tipo" class="text-xs flex items-start space-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                            v-tooltip="'¿Qué tipo de participante sos?'"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                            />
                          </svg>

                          <div>{{ it.tipo | capitalizar }}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        item.respuestas &&
                        !Array.isArray(item.respuestas) &&
                        Object.keys(item.respuestas).length > 0
                      "
                      class="text-xs flex items-start space-x-2 mb-2 max-w-sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                        />
                      </svg>

                      <div class="flex flex-col">
                        <div class="mb-1" v-for="(resp, key) in item.respuestas" :key="key">
                          <div class="font-normal">{{ key | parseKey }}</div>
                          <div class="">{{ resp }}</div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="py-3 px-6 text-right">
                    <div class="flex justify-end space-x-2 items-center">
                      <a v-tooltip="'Cambiar entradas a otro evento'" class="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-indigo-500"
                          @click="cambiarEvento(item)"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      </a>
                      <a v-tooltip="'Hacer devolución'" class="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-red-500"
                          @click="confirmarDevolucion(item)"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                          />
                        </svg>
                      </a>
                      <a v-tooltip="'Descargar entradas'" class="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 cursor-pointer hover:text-purple-500 hover:scale-110"
                          @click="descargarEntradas(item._id)"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                          />
                        </svg>
                      </a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                        />
                      </svg>

                      <w-price-v-2
                        :currency="currency"
                        class="font-normal"
                        :value="
                          item.entradas.reduce((acc, entrada) => {
                            if (!entrada.precio) {
                              return acc;
                            }
                            return acc + entrada.precio[currency];
                          }, 0)
                        "
                      />
                    </div>
                    <div class="flex item-center justify-center">
                      <!-- <div class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        </div> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import RangeBar from "@/components/RangeBar.vue";
export default {
  layout: "default",
  components: {
    RangeBar,
  },
  data() {
    return {
      event: null,
      compras: [],
      participantes: null,
      validos: null,
      canjeados: null,
      searchTerm: "",
      downloading: false,
      showReturn: false,
      showAgregarEntradas: false,
      showCambiarEvento: false,
      returnItem: null,
    };
  },
  mounted() {},
  async created() {
    this.load();
  },
  computed: {
    comprasFiltradas() {
      const searchTermLower = this.searchTerm.toLowerCase();

      return this.compras.data.filter((compra) => {
        const join = compra.entradas
          .map((entrada) => {
            return (
              entrada?.nombre?.toLowerCase() +
              " " +
              entrada?.apellido?.toLowerCase() +
              " " +
              compra?.email?.toLowerCase() +
              " " +
              compra._id.toLowerCase()
            );
          })
          .join(" ");

        return join.includes(searchTermLower);
      });
    },
    currency() {
      return this.event.preferredCurrency;
    },
    totalVentas() {
      console.log("COMPRAS", this.compras);
      if (!this.compras.data) return;
      return this.compras.data.reduce((total, compra) => {
        let subTotal = 0;
        if (compra.status === "pago") {
          subTotal = compra.entradas.reduce((acc, entrada) => {
            if (!entrada.precio) {
              return acc;
            }
            return acc + entrada.precio[this.currency];
          }, 0);
          console.log("subTotal", subTotal);

          // Stripe es el 3% + 0.30 USD
          if (compra.tipo === "stripe") {
            const comision = subTotal * 0.045 + 0.3;
            subTotal = subTotal - comision;
          }
          // Paypal es el 7.4% + 0.30 USD
          if (compra.tipo === "paypal") {
            const comision = subTotal * 0.07 + 0.3;
            subTotal = subTotal - comision;
          }
          return total + subTotal;
        }

        if (!compra.datosMercadopago) {
          return total;
        }

        return total + compra.datosMercadopago.totalBruto;
      }, 0);
    },
    totalVentasNeto() {
      console.log("COMPRAS", this.compras);
      if (!this.compras.data) return;
      return this.compras.data.reduce((total, compra) => {
        let subTotal = 0;
        if (compra.status === "pago") {
          subTotal = compra.entradas.reduce((acc, entrada) => {
            if (!entrada.precio) {
              return acc;
            }
            return acc + entrada.precio[this.currency];
          }, 0);
          return total + subTotal;
        }

        if (!compra.datosMercadopago) {
          return total;
        }

        return total + compra.datosMercadopago.totalBruto;
      }, 0);
    },
    // totalVentasNeto() {
    //   if (!this.compras.data) return;
    //   return this.compras.data.reduce((total, compra) => {
    //     if (!compra.datosMercadopago) {
    //       return total;
    //     }
    //     return total + compra.datosMercadopago.totalNeto;
    //   }, 0);
    // },
    totalEntradas() {
      if (!this.ventas) return 0;
      return this.ventas.reduce((acc, venta) => {
        acc += venta.total;
        return acc;
      }, 0);
    },
    totalCupos() {
      if (!this.ventas) return 0;
      return this.ventas.reduce((acc, venta) => {
        acc += venta.cupo;
        return acc;
      }, 0);
    },
  },
  methods: {
    ...mapActions("pago-evento", { obtenerCompras: "find" }),
    ...mapActions("eventos-ventas-entradas", { obtenerVentas: "find" }),
    ...mapActions("eventos", {
      obtenerEvento: "get",
    }),
    ...mapActions("descargar-participantes", {
      descargar: "get",
    }),
    ...mapActions("descargar-pago-entradas", {
      descargarPagos: "get",
    }),
    async load() {
      let res = await this.obtenerCompras({
        query: {
          "evento._id": this.$route.params.id,
          status: "pago",
          $select: [
            "evento._id",
            "entradas",
            "email",
            "createdAt",
            "respuestas",
            "datosMercadopago",
            "status",
            "tipo",
          ],
          $sort: {
            createdAt: -1,
          },
          $limit: 10000,
        },
      });

      let resVentas = await this.obtenerVentas({
        query: {
          eventoId: this.$route.params.id,
          $limit: 10000,
        },
      });

      this.ventas = resVentas;
      this.compras = res;
      console.log("this.compras", this.compras);
      this.event = await this.obtenerEvento(this.$route.params.id);
    },
    agregarEntradas() {
      this.showAgregarEntradas = true;
    },
    cambiarEvento(item) {
      this.showCambiarEvento = true;
      this.returnItem = item;
    },
    filtrar(email) {
      console.log("filtrar", email);
      this.searchTerm = email;
      this.load();
    },
    confirmarDevolucion(item) {
      this.showReturn = true;
      this.returnItem = item;
    },
    async descargarParticipantes() {
      this.downloading = true;
      const id = this.$route.params.id;
      const res = await this.descargar(id);
      const participantes = res.participantes;

      // Buscar entre los participantes, el que tenga mas respuestas (es un objeto, hay que contar los keys) y devolver el array de keys
      const preguntas = participantes
        .reduce((acc, participante) => {
          const respuestas = participante.respuestas;
          if (!respuestas) return acc;
          const keys = Object.keys(respuestas);
          acc = [...acc, ...keys];
          return acc;
        }, [])
        .filter((item, index, self) => self.indexOf(item) === index);

      console.log("preguntas", preguntas);

      // Convertir a CSV y descargar
      let csv = participantes
        .map((participante) => {
          // Nombre y apellido, castear a mayúscula la primera letra. Email todo en minúscula.

          const nombre = participante.nombre
            .split(" ")
            .map((word) => {
              if (!word) return;
              return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");

          const apellido = participante.apellido
            .split(" ")
            .map((word) => {
              if (!word) return;
              return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");

          const correo = participante.correo ? participante.correo.toLowerCase() : "";

          // Obtener (si hay), respuestas a las preguntas del participante
          const respuestas = preguntas
            .reduce((acc, pregunta) => {
              if (!participante.respuestas) return acc;
              acc = [...acc, participante.respuestas[pregunta]];
              return acc;
            }, [])
            // Reemplazar las comas, para evitar que se rompa el CSV
            .map((item) => {
              if (!item) return;
              return item.replace(/,/g, " ");
            });

          return [
            nombre,
            apellido,
            correo,
            participante.nombreEntrada,
            participante.tipoEntrada,
            participante.institucion,
            ...respuestas,
          ].join(",");
        })
        .join("\n");

      // Agregar cabeceras al CSV
      const cabeceras = [
        "Nombre",
        "Apellido",
        "Correo",
        "Nombre de entrada",
        "Tipo de entrada",
        "Institución",
        ...preguntas,
      ].join(",");

      csv = cabeceras + "\n" + csv;

      const link = document.createElement("a");
      link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      link.download = "participantes-" + id + ".csv";
      link.click();

      setTimeout(() => {
        this.downloading = false;
      }, 500);
    },

    redondearA2Decimales(numero) {
      return Math.round(numero * 100) / 100;
    },

    async descargarVentas() {
      this.downloading = true;
      const id = this.$route.params.id;
      const res = await this.descargarPagos(id);
      console.log("descargarPagos", res);
      const ventas = res.ventas;
      // Convertir a CSV y descargar
      let csv = ventas
        .map((item) => {
          // Nombre y apellido, castear a mayúscula la primera letra. Email todo en minúscula.

          const nombre = item.nombre
            .split(" ")
            .map((word) => {
              if (!word) return;
              return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");

          const apellido = item.apellido
            .split(" ")
            .map((word) => {
              if (!word) return;
              return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");

          const correo = item.correo ? item.correo.toLowerCase() : "";

          const precioEntrada = correo ? 20 : 0;
          const totalBruto = precioEntrada * item.cantidad;
          // Hay que separar el 12% para Orsai Tech y el 5% para Orsai Comunicacion
          const totalProcesador =
            precioEntrada > 0 ? this.redondearA2Decimales(totalBruto * 0.03 + 0.3) : 0;
          const totalOrsaiTech = this.redondearA2Decimales(totalBruto * 0.12);
          const totalOrsaiComunicacion = this.redondearA2Decimales(totalBruto * 0.05);
          const totalNeto = this.redondearA2Decimales(
            totalBruto - totalOrsaiTech - totalOrsaiComunicacion
          );
          const totalComisiones = this.redondearA2Decimales(totalBruto - totalNeto);
          return [
            nombre,
            apellido,
            correo,
            item.nombreEntrada,
            item.cantidad,
            totalNeto,
            totalComisiones,
            totalProcesador,
            totalOrsaiTech,
            totalOrsaiComunicacion,
            totalBruto,
          ].join(",");
        })
        .join("\n");

      // Agregar cabeceras al CSV
      const cabeceras = [
        "Nombre",
        "Apellido",
        "Correo",
        "Nombre de entrada",
        "Cantidad",
        "Total Neto",
        "Total Comisiones",
        "Total Procesador",
        "Total Orsai Tech",
        "Total Orsai Comunicación",
        "Total Bruto",
      ].join(",");

      csv = cabeceras + "\n" + csv;

      const link = document.createElement("a");
      link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      link.download = "participantes-" + id + ".csv";
      link.click();

      setTimeout(() => {
        this.downloading = false;
      }, 500);
    },
    doSearch() {
      console.log("doSearch");
    },
    async descargarEntradas(id) {
      // https://api.orsai.org/descargar-entradas/64de65e0fd74940010598fab
      const link = document.createElement("a");

      // Si el host el localhost, usar la API local
      if (window.location.host.includes("localhost")) {
        link.href = `http://localhost:3030/descargar-entradas/${id}`;
      } else {
        link.href = `https://api.orsai.org/descargar-entradas/${id}`;
      }
      link.download = "entradas" + id + ".pdf";
      link.click();
    },
  },
  filters: {
    formatDate(value) {
      return [
        moment(value)
          .format("dddd")
          .match(/^[a-z]/i)[0]
          .toUpperCase() + moment(value).format("dddd").slice(1),
        moment(value).format("D"),
        moment(value)
          .format("MMMM")
          .match(/^[a-z]/i)[0]
          .toUpperCase() + moment(value).format("MMMM").slice(1),
        moment(value).format("YYYY"),
        moment(value).format("HH:mm"),
      ].join(" ");
    },
    capitalizar(value) {
      if (!value) return;
      return value
        .split(" ")
        .map((word) => {
          if (!word) return;
          return word[0].toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    parseKey(value) {
      // Capitalizar primer letra y reemplazar guiones por espacios
      return value[0].toUpperCase() + value.slice(1).replace(/-/g, " ");
    },
    tipoEducacion(value) {
      return [
        { value: "profe", label: "Soy profe de Lengua y literatura" },
        { value: "otro", label: "Soy profe de otra materia" },
        { value: "estudiante", label: "Soy estudiante de profesorado" },
        { value: "comunidad", label: "Solo soy de Comunidad Orsai" },
      ].find((item) => item.value === value).label;
    },
  },
};
</script>
<style>
/* Only desktop */
@media (min-width: 768px) {
  .md-w-128 {
    width: 27rem !important;
  }
}
</style>
