var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed z-10 inset-0 overflow-y-auto",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('FormulateForm',{on:{"submit":_vm.procesar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"},[_c('div',{staticClass:"bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"},[_c('div',{staticClass:"sm:flex sm:items-start"},[_c('div',{staticClass:"mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900",attrs:{"id":"modal-title"}},[_vm._v(" Agregar Entradas ")]),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-sm text-gray-500"},[_vm._v(" Agregá una persona, la cantidad de entradas que se le van a asignar. ")])]),_c('div',{staticClass:"mt-2 w-full"},[(_vm.loader)?_c('w-loading',{staticClass:"w-full",attrs:{"label":_vm.loader === 'processing' ? 'Generando Entradas' : null}}):_c('div',[_c('FormulateInput',{attrs:{"type":"select","name":"nombreEntrada","label":"Tipo de entrada","placeholder":"Entrada","validation":"required","validation-name":"entrada","options":_vm.evento.entradas.map(function (entrada) {
                        return {
                          value: entrada.nombre,
                          label: entrada.nombre,
                        };
                      })}}),_c('div',{staticClass:"flex mb-0 p-0"},[_c('FormulateInput',{staticClass:"w-1/2 mr-1 mb-0",attrs:{"type":"text","name":"nombre","label":"Nombre","placeholder":"Nombre","validation":"required","validation-name":"nombre"}}),_c('FormulateInput',{staticClass:"w-1/2 ml-1 mb-0",attrs:{"type":"text","name":"apellido","label":"Apellido","placeholder":"Apellido","validation":"required","validation-name":"apellido"}})],1),_c('FormulateInput',{attrs:{"type":"email","name":"email","label":"Correo electrónico","placeholder":"Correo electrónico","validation":"required|email","validation-name":"Correo electrónico"}}),_c('FormulateInput',{attrs:{"type":"text","name":"telefono","label":"Teléfono","placeholder":"Teléfono","validation":"required","validation-name":"Teléfono"}}),_c('FormulateInput',{attrs:{"type":"select","name":"cantidad","label":"Cantidad de entradas","placeholder":"Cantidad de entradas","validation":"required","validation-name":"Cantidad de entradas","options":[
                      { value: 1, label: '1' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                      { value: 6, label: '6' },
                      { value: 7, label: '7' },
                      { value: 8, label: '8' },
                      { value: 9, label: '9' },
                      { value: 10, label: '10' } ]}})],1)],1)])])]),_c('div',{staticClass:"bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"},[_c('button',{staticClass:"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm",class:isLoading ? 'opacity-50 cursor-not-allowed' : '',attrs:{"disabled":isLoading,"type":"submit"}},[_vm._v(" Agregar participantes ")]),_c('button',{staticClass:"mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" Cancelar ")])])])])]}}]),model:{value:(_vm.participante),callback:function ($$v) {_vm.participante=$$v},expression:"participante"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }