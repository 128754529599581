<template>
  <div>
    {{ formattedPrice }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: () => {
        return "ars";
      },
    },
    decimals: {
      type: Number,
      default: () => {
        return 2;
      },
    },
  },
  computed: {
    parsedPrice() {
      return Math.round(this.value * 100) / 100;
    },
    formattedPrice() {
      let locale = "es-AR";
      if (this.currency == "eur") {
        // Europa
        locale = "es-ES";
      }

      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: this.currency,
        minimumFractionDigits: this.decimals,
      }).format(this.parsedPrice);
    },
  },
};
</script>

<style lang="css"></style>
