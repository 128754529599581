import { render, staticRenderFns } from "./ventas.vue?vue&type=template&id=a7c4f404&"
import script from "./ventas.vue?vue&type=script&lang=js&"
export * from "./ventas.vue?vue&type=script&lang=js&"
import style0 from "./ventas.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WAgregarEntradas from '@/components/WAgregarEntradas.vue'
import WCambiarEvento from '@/components/WCambiarEvento.vue'
import WConfirmarDevolucionEntrada from '@/components/WConfirmarDevolucionEntrada.vue'
import WPriceV2 from '@/components/WPriceV2.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WAgregarEntradas,WCambiarEvento,WConfirmarDevolucionEntrada,WPriceV2,WTopBar})
